import React, { useState, useEffect, useMemo, useCallback } from 'react';
import openingNoticeImage from '../../../images/events/opening_notice.jpg';

const PopupNotice = () => {
    const [showNotice, setShowNotice] = useState(false);
    const [hasPopupClosed, setHasPopupClosed] = useState(false);

    // Memoize the date objects to prevent unnecessary recalculations
    const noticeEndDate = useMemo(() => new Date(2025, 1, 28), []);

    useEffect(() => {
        if (new Date() < noticeEndDate && !hasPopupClosed) {
            const openNoticeTimer = setTimeout(() => {
                setShowNotice(true);
            }, 1500);

            return () => clearTimeout(openNoticeTimer);
        }
    }, [noticeEndDate, hasPopupClosed]);

    // Handlers
    const closeNotice = useCallback(() => {
        setShowNotice(false);
        setHasPopupClosed(true);
    }, []);

    const handleOverlayClick = (e) => {
        if (e.target.className === 'modal-overlay') {
            setShowNotice(false);
        }
    };

    return (
        <>
            {showNotice && (
                <div 
                    className="modal modal-overlay" 
                    style={{ display: "block" }} 
                    onClick={handleOverlayClick}
                >
                    <div 
                        // className="modal-dialog modal-lg modal-dialog-centered"
                        className="modal-dialog modal-fullscreen" 
                        onClick={(e) => e.stopPropagation()}
                    >
                        <div className="modal-content">
                            {/* <div className="modal-header bg-warning">
                                <h5 className="modal-title text-uppercase text-center">
                                    Avis à nos clients
                                </h5>
                                <button 
                                    type="button" 
                                    className="btn-close" 
                                    onClick={closeNotice} 
                                    aria-label="Close"
                                ></button>
                            </div> */}
                            <div className="modal-body text-center p-0">
                                <img src={openingNoticeImage} className="img-fluid" alt="Avis de réservation" />
                            </div>
                            <div className="modal-footer bg-warning">
                                <button 
                                    type="button" 
                                    className="btn btn-dark" 
                                    onClick={closeNotice}
                                >
                                    Fermer
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default PopupNotice;