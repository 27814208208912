import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

const Login = () => {
    const [formData, setFormData] = useState({ email: "", password: "" });
    const [error, setError] = useState("");
    // const [success, setSuccess] = useState(false);

    const navigate = useNavigate();

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const { email, password } = formData;

        // Simple validation
        if (!email || !password) {
            setError("Tous les champs sont requis!");
            return;
        }

        // Mock authentication
        const validEmails = [
            "tranduyhoang2592@gmail.com", 
            "namwood2019@gmail.com", 
            "manh-hiep.vu.1@ulaval.ca"
        ];
        const validPasswords = ["4182552318", "4182629725", "5817488377"];

        if (validEmails.includes(email) && validPasswords.includes(password)) {
            setError("");
            sessionStorage.setItem("user", email);
            navigate('/'); // Redirect to home page
        } else {
            setError("Courriel ou mot de passe invalide !");
        }
    };

    return (
        <section className="pt-5">
            <div>
                <h2 className="section-title">Golf & Bar Services</h2>
                <form className="pt-5" onSubmit={handleSubmit}>
                    <div className="mb-3">
                        <label htmlFor="email">Courriel</label>
                        <input
                            type="text"
                            id="email"
                            className="form-control form-control-lg"
                            name="email"
                            value={formData.email}
                            onChange={handleInputChange}
                            placeholder="Courriel"
                        />
                    </div>
                    <div className="mb-3">
                        <label htmlFor="password">Password</label>
                        <input
                            type="password"
                            id="password"
                            className="form-control form-control-lg"
                            name="password"
                            value={formData.password}
                            onChange={handleInputChange}
                            placeholder="Mot de passe"
                        />
                    </div>
                    <br />
                    <button type="submit" className="btn btn-lg btn-outline-warning text-uppercase">Se Connecter</button>
                </form>
                {error && <p style={{ color: "red" }}>{error}</p>}
                {/* {success && <p style={{ color: "green" }}>Connexion réussie !</p>} */}
            </div>
        </section>
    );
};

export default Login;