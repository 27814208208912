import React from 'react';
import './homePage.css';

import HomeBanner from './HomeBanner';
import HomeAboutUs from './HomeAboutUs';
import HomeServices from './HomeServices';
// import HomeBar from './HomeBar';
import HomeFaqs from './HomeFaqs';
import HomeContact from './HomeContact';
// import Login from '../auth/Login';

import PopupNotice from '../../components/popups/notices/PopupNotice';

function HomePage() {
    // const [isLogin, setIsLogin] = useState(false);
    // const user = sessionStorage.getItem("user");
    // const navigate = useNavigate();

    // useEffect(() => {
    //     if (user) {
    //         setIsLogin(true);
    //         navigate('/');
    //     } else {
    //         setIsLogin(false);
    //     }
    // }, [user, navigate]);

    return (
        <main>
            <HomeBanner />
            <PopupNotice />
            <HomeAboutUs />
            {/* {isLogin ? (
                <>
                    <HomeServices />
                </>
            ) : (
                <Login />
            )} */}
            <HomeServices />
            {/* <HomeBar /> */}
            <HomeFaqs />
            <HomeContact />
        </main>
    )
}

export default HomePage