import React from 'react';
import './serviceCard.css';
// import { COLOR_BASIC, COLOR_PRO, COLOR_VIP } from '../../utils/colorCode';

function ServiceCard({ service }) {
    return (
        <div className="col-md-4 mb-4">
            <div className="card bg-light">
                <div className="card-body">
                    <h5 className="text-center text-uppercase mb-3">{service.category}</h5>
                    <ul className="list-group mb-3">
                        <li className="list-group-item d-flex justify-content-between lh-sm">
                            <div>
                                <h6 className="my-0">Lundi au vendredi de jour</h6>
                                <small className="text-muted">de 7h à 17h</small>
                            </div>
                            <span className="fw-bold">{service.priceDay}</span>
                        </li>
                        <li className="list-group-item d-flex justify-content-between lh-sm">
                            <div>
                                <h6 className="my-0">Lundi au vendredi de soir</h6>
                                <small>à partir de 17h</small>
                            </div>
                            <span className="fw-bold">{service.priceNight}</span>
                        </li>
                        <li className="list-group-item d-flex justify-content-between">
                            <div>
                                <h6 className="my-0">Samedi et dimanche</h6>
                                <small>jour et soir</small>
                            </div>
                            <span className="fw-bold">{service.priceWeekend}</span>
                        </li>
                    </ul>
                    <div className="card-footer">
                        <div className="row d-grid gap-2">
                            <button 
                                type='button' 
                                className='btn btn-outline-primary btn-lg mt-3'
                            >
                                Réserver
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        // <div className="row mb-3">
        //     <div className="col-md-5 col-sm-12">
        //         <img className="img-fluid rounded" src={service.previewImg} alt={service.title} />
        //     </div>
        //     <div className="col-md-7 col-sm-12">
        //         <h3 className="text-white">{service.title}</h3>
        //         <p className="text-white">{service.description}</p>
        //         <a href="#">Réserver</a>
        //     </div>
        //     <div className="col-md-8 col-sm-12">
        //         <div className="card-wrapper py-3">
        //             <div 
        //                 className="card" 
        //                 style={{"--card--color": service.category === "VIP" ? COLOR_VIP : service.category === "PRIME" ? COLOR_PRO : COLOR_BASIC}}
        //             >
        //                 <div className="box">
        //                     <div className="icon">
        //                         <div className="iconBox">
        //                             <h4>{service.title}</h4>
        //                         </div>
        //                     </div>
        //                     <div className="content">
        //                         <h3 className="price">{service.price}</h3>
        //                         <p>{service.description}</p>
        //                         <a href="#">Réserver</a>
        //                     </div>
        //                     <div className="card-note">
        //                         <h5>{service.appliedDate}</h5>
        //                     </div>
        //                 </div>
        //             </div>
        //         </div>
        //     </div>
        // </div>
    )
}

export default ServiceCard