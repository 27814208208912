import React, { useState, useEffect } from 'react';
import './header.css';
import logo from '../../images/logo-jgolf.png';
import NavListItem from './NavListItem';
import navListData from '../../data/navListData';
import ReservationButton from '../../components/buttons/ReservationButton';

function Header() {

    const [navList, setNavList] = useState(navListData);
    const [scroll, setScroll] = useState(0);
    const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

    const handleNavOnClick = id => {
        const newNavList = navList.map(nav => {
            nav.active = false;
            if (nav._id === id) nav.active = true;
            return nav;
        });

        setNavList(newNavList);
    };

    useEffect(() => {
        const handleScroll = () => {
            setScroll(window.scrollY);
        };

        window.addEventListener('scroll', handleScroll);

        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    const toggleMobileMenu = () => {
        setIsMobileMenuOpen(!isMobileMenuOpen);
    };

    return (
        <header className={`navBar ${scroll > 100 ? 'scrolled' : ''}`}>
            <div className="navBarWrapper">
                {/* Logo */}
                <div className="navBarLogo">
                    <a href="/" className="navBarMenuLink">
                        <img className="logoImg" src={logo} alt="Logo" />
                    </a>
                </div>

                {/* Desktop Menu */}
                <div className="navBarMenu">
                    <ul className="navBarMenuList">
                        {navListData.map((nav) => (
                            <NavListItem key={nav._id} nav={nav} navOnClick={handleNavOnClick} />
                        ))}
                    </ul>
                </div>

                {/* Reservation Button */}
                <ReservationButton icon={<i className="fa-regular fa-calendar-days"></i>} name="Réserver" />

                {/* Mobile Menu */}
                <div className="navBarMobileMenu">
                    {/* Hamburger Icon */}
                    <div
                        className="navBarMobileMenuToggle"
                        onClick={toggleMobileMenu}
                        aria-label={isMobileMenuOpen ? 'Close menu' : 'Open menu'}
                    >
                        {isMobileMenuOpen ? (
                            <i className="fas fa-times fa-2x"></i>
                        ) : (
                            <i className="fas fa-bars fa-2x"></i>
                        )}
                    </div>

                    {/* Mobile Menu Items */}
                    <div className={`navBarMobileMenuItems ${isMobileMenuOpen ? 'active' : ''}`}>
                        <ul className="navBarMobileMenuList">
                            {navListData.map((nav) => (
                                <li key={nav._id} className="navBarMobileMenuItem">
                                    <NavListItem nav={nav} navOnClick={handleNavOnClick} />
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
            </div>
        </header>
    );
}

export default Header;