import React from 'react';
import './homeAboutUs.css';

import overviewImg from '../../images/about/about-overview.jpg';

function HomeAboutUs() {

    return (
        <section id="about" className="about">
            <div className="row text-center text-white text-uppercase">
                <h2>Un nouveau centre de golf virtuel ouvre ses portes !</h2>
            </div>
            <br /><br />
            <div className="row">
                <h4 className="section-title">Bienvenu Chez J-Golf !</h4>
                <figure>
                    <blockquote className="blockquote">
                        <p className="text-white pt-3">
                            Situé en plein cœur de Ste-Foy, J-golf, conjugue à la perfection élégance et divertissement, au sein de notre établissement contemporain de plus de 6000 pieds carrés. Dans un espace moderne nous aurons l’opportunité de vous faire voyager en accédant à plus de 1250 parcours de golf à travers le monde !
                        </p>
                    </blockquote>
                </figure>
                <h4 className="text-white">
                    <strong>Le réalisme comme jamais à votre porté, grâce à la nouvelle technologie Golf-in</strong>
                </h4>
                <figure>
                    <blockquote className="blockquote">
                        <p className="text-white">
                           "La précision de nos simulateurs procure l’expérience de jeu virtuelle la plus complète sur le marché et reproduit les meilleurs parcours de golf. Nos structures, technologies et logiciels recréent le réalisme d’une partie de golf jusqu’aux moindres détails, du coup de départ au coup roulé."
                        </p>
                    </blockquote>
                    <figcaption className="blockquote-footer pt-2">
                        <span className="h6">Simulateur de Golf | Résidentiel & Commercial | Golf In</span>
                    </figcaption>
                </figure>
                <figure>
                    <blockquote className="blockquote">
                        <p className="text-white">
                            <span className="fs-4 fw-bold">J-Golf</span> se distingue de la concurrence, en proposant un menu de cocktails exclusif et une expérience hors du commun, incluant des soirées de jazz live occasionnelles, une salle d’arcades familiale, ainsi que six simulateurs de golfs haut de gamme de 198 pouces et deux écrans <span className="fst-italic fw-bold">"Curve"</span> de 204 pouces. Sans oublier nos deux salles VIP, alliant golf et Karaoke, qui sauront satisfaire toutes vos attentes lors de vos événements !  
                        </p>
                    </blockquote>
                </figure>
                <h4 className="text-white">
                    <strong>Notre équipe dévouée n’attend plus que vous ! Venez vous immerger dans notre univers passionnant comme nulle part ailleurs, J-golf vous attends !</strong>
                </h4>
                {/* <div className="col-md-6 about__text">
                    <p className="about__text-subtitle">Our Story</p>
                    <h2 className="about__text-title">Every Flavor Tells a Story</h2>
                    <p className="about__text-content">
                        Lorem ipsum dolor sit amet consectetur adipisicing elit. Aliquid fuga nisi distinctio natus harum consequatur error, dolorum totam commodi ea, alias numquam voluptatum dolore libero vero quisquam quis tenetur unde ut animi nobis. Natus, dicta. Quisquam, officia modi veritatis aliquam vitae consequatur corrupti sunt laboriosam nulla voluptatum. Enim, dolor voluptate.
                    </p>
                    <div className="about__text-contact-label">
                        Book Through Call
                    </div>
                    <a href="tel:+15817482075" className="about__text-contact-number">+1 (581) 748-2075</a>
                    <a href="#" className="">
                        <span>Read More</span>
                        <span>Read More</span>
                    </a>
                </div>
                <div className="col-md-6 about__figure">
                    <img className="about__figure-image-100w about__figure-image-overview" src={overviewImg} loading="lazy" alt="Overview Image" />
                    <div className="about__figure-image about__figure-image-top">
                        <img className="about__figure-image-100w" src={overviewImg} />
                    </div>
                    <div className="about__figure-image about__figure-image-bottom">
                        <img className="about__figure-image-100w" src={overviewImg} />
                    </div>
                </div> */}
            </div>
        </section>
    )
}

export default HomeAboutUs