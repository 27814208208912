import React, {useState, useEffect} from 'react';
import './homeServices.css';
import ServiceCard from '../../components/cards/ServiceCard';

function HomeServices() {
    const initialFilterList = [
        { _id: 1, name: 'TOUS', active: true },
        { _id: 2, name: 'ÉCONOMIE', active: false },
        { _id: 3, name: 'VIP', active: false },
        { _id: 4, name: 'KARAOKÉ', active: false },
    ];

    const [data, setData] = useState([]);
    const [services, setServices] = useState([]);
    const [filters, setFilters] = useState(initialFilterList);

    const dataServicesUrl = process.env.REACT_APP_DATA_SERVICES_URL;

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(dataServicesUrl);
                const contentType = response.headers.get("content-type");
                
                if (!response.ok) throw new Error(`HTTP error! Status: ${response.status}`);
    
                if (contentType && contentType.includes("application/json")) {
                    const result = await response.json();
                    setData(result);
                } else {
                    throw new Error("Received non-JSON response");
                }
            } catch (error) {
                console.error("Fetch error:", error.message);
            }
        };
    
        fetchData();
        
    }, [dataServicesUrl]);
    
    useEffect(() => {
        setServices(data);
    }, [data]);

    const handleFilterServices = (category) => {
        const updatedFilters = filters.map(filter => ({
            ...filter,
            active: filter.name === category,
        }));
        setFilters(updatedFilters);

        if (category === 'TOUS') {
            setServices(data);
        } else {
            const filteredServices = data.filter(service => service.category === category);
            setServices(filteredServices);
        }
    };

    return (
        <section id="services" className="services">
            <div className="container-fluid">
                <div className="row">
                    <h4 className="section-title">Tarif <small>&</small> Promotion</h4>
                </div>
                <div className="row">
                    <ul className="filters">
                        {filters.map(filter => (
                            <li 
                                key={filter._id} 
                                className={filter.active ? 'active' : ''}
                                onClick={() => handleFilterServices(filter.name)}
                            >
                                {filter.name}
                            </li>
                        ))}
                    </ul>
                </div>
                <div className="row mt-5">
                    {services && services.length > 0 ? (
                        services.slice(0, 3).map(service => (
                            <ServiceCard key={service._id} service={service} />
                        ))
                    ) : (
                        <p>No services available</p>
                    )}
                </div>
            </div>
        </section>
    );
}

export default HomeServices