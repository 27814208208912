import React from 'react';
import './footer.css';
import FooterNavItem from './FooterNavItem';

function Footer() {
    // const usefulLinks = [
    //     'Home',
    //     'Movies',
    //     'MyList',
    //     'Term of service',
    //     'Privacy Policy',
    // ];

    // const locations = [
    //     'Location A',
    //     'Location B',
    //     'Location C',
    //     'Location D'
    // ];

    const businessHours = [
        'Lundi au mercredi: 7h à 0h',
        'Jeudi au samedi: 7h à 3h',
        'Dimanche: 7h à 0h'
    ];

    return (
        <footer id="footer" className="footer">
            <div className="footer-top">
                <div className="container">
                    <div className="row gy-4">
                        <div className="col-lg-4 col-md-12 footer-links">
                            <h4>Heures d'ouverture</h4>
                            <ul>
                                {
                                    businessHours.map(time => (
                                        <FooterNavItem key={time} name={time} />
                                    ))
                                }
                            </ul>
                        </div>
                        <div className="col-lg-4 col-md-12 footer-contact">
                            <h4>Contactez-nous</h4>
                            <p>
                                2360 chemin Ste-Foy, SS25 <br />
                                Québec, G1V 4H2, Canada <br />
                                <strong>Phone: </strong> +1 (581) 659-7656
                                <br />
                                <strong>Email:</strong> jgolfpyramide@gmail.com
                                <br />
                            </p>
                        </div>
                        <div className="col-lg-4 col-md-12 footer-info">
                            <h4>Suivez-nous</h4>
                            <div className="social-links mt-3">
                                <a 
                                    // href="https://www.facebook.com/namforfood"
                                    href="/"
                                    className="facebook"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    <i className="fa-brands fa-facebook"></i>
                                </a>
                                <a 
                                    href="/" 
                                    className="instagram"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    <i className="fa-brands fa-instagram"></i>
                                </a>
                                <a 
                                    href="/" 
                                    className="youtube"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    <i className="fa-brands fa-youtube"></i>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container">
                <div className="copyright">
                    &copy; 2024{' '}
                    <strong>
                        <span>J-Golf Pyramide</span>
                    </strong>
                    . Tous droits réservés
                </div>
                {/* <div className="social-links mt-3">
                    <a 
                        href="#" 
                        className="facebook"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <i className="fa-brands fa-facebook"></i>
                    </a>
                    <a 
                        href="#" 
                        className="instagram"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <i className="fa-brands fa-instagram"></i>
                    </a>
                    <a 
                        href="#" 
                        className="youtube"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <i className="fa-brands fa-youtube"></i>
                    </a>
                </div> */}
                {/* <div className="credits">
                    Designed by <a href="#">Nam Wood</a>
                </div> */}
            </div>
        </footer>
    );
}

export default Footer